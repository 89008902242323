.cardBackground-battleBall{
    border-radius: 25px;
    background: 2px #73AD21;
    padding: 20px;
    width: 90%;
    height: 35%;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 35px;
}

.gameImage-battleBall{
    background-image: url('./shorty.png')
}

.gameTitle-battleBall{
    margin-top: 25px;
    font-family: 'ActionJ';
    font-size: 2.5rem;
}

.gameReleaseDate-battleBall{
    margin-top: 20px;
}

.mainInfo-battleBall{
    float:left;
}

.storeLink-battleBall{
    width: 140px;
    height: 40px;
    float:right;
    margin-top: -10px;
}

.inLinePrice-battleBall{
    float:right;
    margin-top: 20px;
}

.price-battleBall{
    float:left;
    margin-right: 25px;
}

.summaryText-battleBall{
    display:inline-block;
    padding-bottom: 14px;
}

.card-side-battleBall{
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom:25px;
    width: 97%;
    border: 1px solid rgba(153, 153, 153, 0.4);
  }
  .card-side-battleBall + .card-side-battleBall {
    margin-top: 20px;
  }
  .card-side-battleBall .card-header-battleBall {
    min-height: 120px;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    background-image: url('./HeadSplash.PNG')
  }
  .card-side-battleBall .card-content-battleBall {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    padding: 0 1em;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 0.9em;
    line-height: calc(0.9em + 8px);
  }
  .card-side-battleBall .card-content-battleBall a {
    color: currentColor;
    text-decoration: none;
  }
  .card-side-battleBall .card-footer-battleBall {
    box-sizing: border-box;
    -webkit-box-flex: 0;
            flex: 0 0 7.5%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .card-side-battleBall .card-footer-battleBall .action-link {
    text-decoration: none;
    color: transparent;
  }
  .card-side-battleBall .card-footer-battleBall .action-link:after {
    height: 20px;
    width: 20px;
    content: "";
    margin-bottom: 50%;
    border-top: 5px solid rgb(66, 221, 241);
    border-right: 5px solid rgb(231, 77, 236);
    display: block;
    position: relative;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0;
    left: -20%;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }
  .card-side-battleBall:hover .action-link:after {
    -webkit-transform: rotate(45deg) translate(0px, 0px);
            transform: rotate(45deg) translate(0px, 0px);
    left: 30%;
    opacity: 1;
  }
  .bottomLink{
    display: none;
  }
  .storeLink-battleBall-bottom{
    display: none;
  }
  .price-battleBall-bottom{
    display: none;
  }


  @media screen and (min-width:1921px){
    .gameTitle-battleBall{
      margin-top: 25px;
      font-size: 3.5rem;
    }
    .gameReleaseDate-battleBall{
      margin-top: 25px;
      font-size:1.5rem;
      line-height: 3rem;
    }
    .summaryText-battleBall{
      display:inline-block;
      font-size:2.1rem;
      line-height: 2.5rem;
    }
    .storeLink-battleBall{
      width: 160px;
      height: 60px;
      margin-top: -18.5;
    }
    .price-battleBall{
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width:1135px){
    .card-side-battleBall .card-footer-battleBall .action-link:after {
    
      margin-bottom: 55%;
    }
  }

  @media screen and (max-width: 960px) {
    .card-side-battleBall {
      display: block;
    }
    .card-side-battleBall .card-header-battleBall {
      display: block;
      height: 120px;
    }
    .card-side-battleBall .card-footer-battleBall {
      display: block;
      text-align: right;
      padding-bottom: 20px;
      margin: 0 20px;
    }
    .card-side-battleBall .card-footer-battleBall .action-link {
      text-decoration: none;
      color: blue;
    }
    .card-side-battleBall .card-footer-battleBall .action-link:after {
      display: none;
    }
    .card-side-battleBall .card-header-battleBall {
      background-image: url('./shorty.png')
    }
  }

  @media screen and (max-width: 630px){
    
    .bottomLink{
      padding: 0 1em;
      display: block;
      margin-top: 10px;
    }

    .storeLink-battleBall-bottom{
      display: inline;
      width: 120px;
      height: 35px;
      float:right;
      margin-top: -7.5px;
    }

    .price-battleBall-bottom{
      display: inline;
    }

    .inLinePrice-battleBall{
      display: none;
    }

    .card-side-battleBall .card-footer-battleBall {
      text-align: center;
      margin-top: 15px;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-right: 0px;
      margin-left: 0px;
      background-color: lavender;
    }

    .card-side-battleBall .card-footer-battleBall .action-link {
      text-decoration: underline;
    }
  }
  