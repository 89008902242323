.legalBackground-PolyPreserve{
    background-color: lightgray;
}

.legalCommon{
    margin-bottom:20px;
    margin-left: 10px;
    text-align: left;
}

.legalElement{
    margin-bottom:20px;
    text-align: center;
}

.legalParagraph{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.legalBulletHeader{
    margin-top: 10px;
}