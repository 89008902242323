.mainPicture{
    display: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 500px;
  }

.btn-container {
    margin-left: 15px;
    margin-right: 15px;
    background-color: gray;
    background-position: center;
    align-items: center;
    align-content: center;
    border-width: normal;
    padding: 4px;
    padding-bottom: 1px;
    outline: none;
    border:none
}

.btn-container:hover{
  background-color: rgb(214, 205, 205);
}

.mini-image{
    width: 150px;
    height: 100px;
    align-items: center;
}

.centered{
    align-items: center;
    /* background-image: url('./milky-way-2695569_1920.jpg'); */
    position: relative;
    display: flex;
    justify-content: center;
    /* background-repeat: repeat; */
    z-index: 10;
    margin:auto;
    width: 100%;
} 

.centeredDots{
    visibility: hidden;
}

.gameTitleFullDescription{
    color:white;
    font-family: 'Big Shoulders Display', cursive;
    text-align: center;
    font-size: 4rem;
}

.gameDescriptionReleaseDate{
    color:white;
    text-align: center;
    font-family: 'Big Shoulders Display', cursive;
    font-size:2rem;
    margin-top: 5px;
    margin-bottom: 10px;
}

.gameDescriptionMetaData{
    /* font-family: 'Josefin Sans', sans-serif; */
    font-family: 'Big Shoulders Display', cursive;
    color:white;
    margin-left: 3.5%;
    margin-top: 2%;
    margin-bottom: 2%;align-items: center;
}

.gameSummaryContent{
    color:rgb(223, 217, 217);;
    font-family: 'Josefin Sans', sans-serif;
    margin:auto;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.25rem;
    padding-top: 10px;
    margin-bottom: 10px;
}

.gameSummaryTitle{
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    font-size: 2rem;
    text-align: left;
    font-family: 'Josefin Sans', sans-serif;
    color:rgb(223, 217, 217);
    margin-left: 15px;
}

.gameSummary-side {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    background-color: rgb(223, 217, 217);;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 25px;
}

.gameSummary-side .gameSummary-header {
    min-height: 120px;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    background-color: #c054ae;
    font-size:1.5rem;
}
.gameSummary-side .gameSummary-Content {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    padding: 0 1em;
    box-sizing: border-box;
    background-color: #99408a;
    font-size: 0.9em;
    line-height: calc(0.9em + 8px);
    top: 50%;
}

.aboutGameHeaders{
    margin-top: 12.5px;
    font-size: 1.25rem;
    color:rgb(223, 217, 217);
    font-family: 'Josefin Sans', sans-serif;
    padding-bottom: 5px;
    padding-top: 10px;
}

.aboutGameDescription{
    color:rgb(223, 217, 217);;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
}

.aboutGame-side {
    display: -webkit-box;
    display: flex;
    overflow: hidden;

    background-color: rgb(223, 217, 217);;
    box-sizing: border-box;
    margin: 0 auto;
    border-top: 1px solid gray;
}
.aboutGame-side .aboutGame-header {
    min-height: 120px;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    background-color:#404040;
    font-size:1.5rem;
}
.aboutGame-side .aboutGame-content {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    padding: 0 1em;
    box-sizing: border-box;
    background-color: #181818;
    font-size: 0.9em;
    line-height: calc(0.9em + 8px);
}

.contactUs{
  background-color: #052d47;
  text-align: center;
  color: white;
  font-size: .5rem;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Big Shoulders Display', cursive;
}

.legalLinks{
    
    color:white;
    margin-top: 22.5px;
    margin-bottom: 22.5px;
    font-size: 1.5rem;
}

.legalLinks:hover{
    color:#99408a;
}

.futureUpdates{
    color:white;
    margin-left: 15px;
    margin-top:25px;
    font-size: 1.5rem;
    font-family: 'Josefin Sans', sans-serif;
}

.textAlign{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top:25px;
}

.the-arrow {
    width: 1px;
    transition: all 0.2s;
  }
  .the-arrow.-left {
    position: absolute;
    top: 60%;
    left: 0;
  }
  .the-arrow.-left > .shaft {
    width: 0;
    background-color: #4c4c4c;
  }
  .the-arrow.-left > .shaft:before, .the-arrow.-left > .shaft:after {
    width: 0;
    background-color: #4c4c4c;
  }
  .the-arrow.-left > .shaft:before {
    transform: rotate(0);
  }
  .the-arrow.-left > .shaft:after {
    transform: rotate(0);
  }
  .the-arrow.-right {
    top: 3px;
  }
  .the-arrow.-right > .shaft {
    width: 1px;
    transition-delay: 0.2s;
  }
  .the-arrow.-right > .shaft:before, .the-arrow.-right > .shaft:after {
    width: 8px;
    transition-delay: 0.3s;
    transition: all 0.5s;
  }
  .the-arrow.-right > .shaft:before {
    transform: rotate(40deg);
  }
  .the-arrow.-right > .shaft:after {
    transform: rotate(-40deg);
  }
  .the-arrow > .shaft {
    background-color: #4c4c4c;
    display: block;
    height: 1px;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;
    top: -5px;
  }
  .the-arrow > .shaft:before, .the-arrow > .shaft:after {
    background-color: #4c4c4c;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    transition: all 0.2s;
    transition-delay: 0;
  }
  .the-arrow > .shaft:before {
    transform-origin: top right;
  }
  .the-arrow > .shaft:after {
    transform-origin: bottom right;
  }
  
  .animated-arrow {
    display: inline-block;
    color: #4c4c4c;
    font-size: 2.25em;
    font-style: italic;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
    text-align: center;
  }
  .animated-arrow:hover {
    color: #808080;
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft {
    width: 64px;
    transition-delay: 0.1s;
    background-color: #808080;
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before, .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    width: 12px;
    transition-delay: 0.1s;
    background-color: #808080;
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before {
    transform: rotate(40deg);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    transform: rotate(-40deg);
  }
  .animated-arrow:hover > .main {
    transform: translateX(17px);
    transform: translateX(80px);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft {
    width: 0;
    transform: translateX(200%);
    transition-delay: 0;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.1s;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
    transform: rotate(0);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    transform: rotate(0);
  }
  .animated-arrow > .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    text-align: center;
  }
  .animated-arrow > .main > .text {
    margin: 0 24px 0 0;
    line-height: 1;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
  }
  .animated-arrow > .main > .the-arrow {
    position: relative;
  }

@media screen and (min-width: 2000px) {
  .mainPicture{
    height: 750px;
  }
}

@media screen and (max-width: 960px) {
    .gameSummaryTitle{
        font-size: 1.5rem;
        margin-left: 5px;
    }

    .aboutGameHeaders{
        font-size: 1rem;
    }

    .gameSummaryContent{
      font-size: 1rem;
      margin-bottom: 10px;
    }

    .aboutGameDescription{
        font-size: .75rem;
        margin-bottom: 10px;
    }

    .futureUpdates{
        font-size: 1.25rem;
        margin-top:15px;
        margin-bottom: 15px;
    }

    .legalLinks{
        font-size: 1.25rem;
    }
    
}

@media screen and (max-width: 960px) {
    .centered{
       visibility: hidden;
    } 

    .centeredDots{
        visibility: visible;
        width:100%;
        background-color: black;
        padding-top:10px;
        margin-top: 0%;
        margin:0;
        display: block;
    }

    .dotsContainer{
        display: inline-block;
        text-align: center;
        width: 85%;
        padding-top: 4px;
    }

    .mainPicture{
        margin-bottom: 0%;
        padding: 0%;
        display: block;
        margin: 0%;
        height: 300px;
    }

    .arrowRight {
        border: solid white;
        border-width: 0 3px 3px 0;
        padding: 4px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 5%;
        float: right;
    
      }

    .arrowRight:hover {
        cursor: pointer;
    }

    .arrowLeft {
        border: solid white;
        border-width: 0 3px 3px 0;
        padding: 4px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        margin-left: 5%;
        float: left;
    }

    .arrowLeft:hover {
        cursor: pointer;
    }

    .btn-container {
        margin-left: 15px;
        margin-right: 15px;
        background-color: rgba(76, 85, 88, 0.534);
        background-position: center;
        border-radius: 0px;
        align-items: center;
        align-content: center;
        border-width: 1px;
        /* padding: 2px; */
        width: 10px;
        height: 10px;
        display: inline-block;
        transform: translateY(-100%);
        outline: none;  /*Gets rid of clicked outline*/
    }
    
    .btn-container:hover{
        background-color: white;
        cursor: pointer;
    }

    .contactUs{
      font-size: .35rem;
    }

    .aboutGame-side {
      display: -webkit-box;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      background-color: rgb(223, 217, 217);;
      box-sizing: border-box;
      margin: 0 auto;
      border-top: none;
  }
  .aboutGame-side .aboutGame-header {
      min-height: 20px;
      flex-direction: column;

      background-position: center center;
      background-size: cover;
      box-sizing: border-box;
      background-color:#404040;
      text-align: center;
      align-items: center;
      font-size:1.5rem;
      width:100%;
  }
  .aboutGame-side .aboutGame-content {
      -webkit-box-flex: 1;
              flex: 1 1 auto;
      padding: 0 1em;
      box-sizing: border-box;
      flex-direction: column;
      background-color: #181818;
      font-size: 0.9em;
      line-height: calc(0.9em + 8px);
  }

  .gameSummary-side{
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-color: rgb(223, 217, 217);;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 25px;
  }

  .gameSummary-side .gameSummary-header {
      min-height: 20px;
      flex-direction: column;
      -webkit-box-flex: 0;
              flex: 0 0 25%;
      background-position: center center;
      background-size: cover;
      box-sizing: border-box;
      background-color: #c054ae;
      font-size:1.5rem;
  }
  .gameSummary-side .gameSummary-Content {
      flex-direction: column;
      -webkit-box-flex: 1;
              flex: 1 1 auto;
      padding: 0 1em;
      box-sizing: border-box;
      background-color: #99408a;
      font-size: 0.9em;
      line-height: calc(0.9em + 8px);
      top: 50%;
  }
    
}

@media screen and (max-width: 700px) {
  .animated-arrow {
    font-size: 1.75rem;
  }
}

@media screen and (max-width:500px){

  .arrowRight {
    margin-right: 3%;
  }

  .arrowLeft {
    margin-left: 3%;
  }

  .animated-arrow:hover {
    color: rgb(58, 57, 57);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before {
    transform: rotate(40deg);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    transform: rotate(-40deg);
  }
  .animated-arrow:hover > .main {
    transform: translateX(2px);
    transform: translateX(10px);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft {
    width: 0;
    transform: translateX(100%);
    transition-delay: 0;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.4s;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
    transform: rotate(0);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    transform: rotate(0);
  }
}

@media screen and (min-width: 500px) {
  .animated-arrow:hover {
    color: rgb(58, 57, 57);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft {
    width: 64px;
    transition-delay: 0.1s;
    background-color:rgb(58, 57, 57);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before, .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    width: 12px;
    transition-delay: 0.1s;
    background-color: rgb(58, 57, 57);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before {
    transform: rotate(40deg);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    transform: rotate(-40deg);
  }
  .animated-arrow:hover > .main {
    transform: translateX(17px);
    transform: translateX(80px);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft {
    width: 0;
    transform: translateX(100%);
    transition-delay: 0;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.1s;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
    transform: rotate(0);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    transform: rotate(0);
  }
}