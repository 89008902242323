.profilePicture{
  border-radius: 50%;
  height: 200px;
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.aboutUsInto{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  height: auto;
  background-color: #fffff0c4;
  border-radius: 1%;
  margin-top: 20px;
}

.aboutUsTitleHeading{
  text-align: left;
  font-family: 'Josefin Sans', sans-serif;
  margin-left: 5px;
  padding-top: 15px;
  font-size: 2rem;
}

.gameTitleHeading{
  text-align: left;
  font-family: 'Josefin Sans', sans-serif;
  margin-left: 5px;
  padding-top: 15px;
  font-size: 1.5rem;
}

.aboutUsText{
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 10px;
  margin-left: 8px;
  padding-bottom: 12.5px;
}

.aboutUsList{
  list-style-type: square;
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 10px;
  margin-left: 35px;
}

.aboutUsListText{
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 10px;
}

.aboutUsFavorite{
  text-align: center;
  color: black;
  font-family: 'Josefin Sans', sans-serif;
  padding-top: 5px;
}

.favoriteSection{
  display: flex;
  padding-bottom: 5px;
}

.favoriteBlock{
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  background-color:#fffff0c4;
  border-radius: 5%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.favoriteList{
  text-align: center;
  list-style-type: none;
}

.favoriteTitle{
  margin-top: 2.5%;
  font-size: 2rem;
  text-align: center;
  color:black;
  font-family: 'Josefin Sans', sans-serif;
}

.favoriteText{
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 8px;
}


@media screen and (max-width: 960px) {
  .favoriteSection{
    display: block;
  }
}

@media screen and (max-width: 800px){
  .favoriteBlock{
    width: 300px;
  }
  
}