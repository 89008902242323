.image-grid {
    margin: 1em auto;
    padding: .5em;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 0em;
    padding-top: 0em;
}

.image-column {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1em;
    margin-top: 1em;
}

.bottomSpacer{
    padding-bottom: 10px;
}
  
.swagItem{
    width:200px;
    height: 200px;
    margin-left: 9%;
   
    border-radius: 5%;
    border: 1px solid rgb(85, 83, 83);
  }


.swagItemContainer{
    width:80%;
    align-content: center;
    align-self: center;
    margin:auto;
}

.merchTitle{
    font-size: 3rem;
    text-align: center;
    margin-top:15px;
    margin-bottom:15px;
    font-family: 'Balsamiq Sans', cursive;
    color:rgb(58, 57, 57);
}

.column img {
    width: 200px;
    height: 200px;
    background: white;
    margin: .5em;
    box-shadow: 0 2px 1px rgba(0,0,0,.1);
  }

  
  .textAlign-other{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top:25px;
}


.the-arrow-other {
    width: 1px;
    transition: all 0.2s;
  }
  .the-arrow-other.-left-other {
    position: absolute;
    top: 60%;
    left: 0;
  }
  .the-arrow-other.-left-other > .shaft-other {
    width: 0;
    background-color: rgb(58, 57, 57);
  }
  .the-arrow-other.-left-other > .shaft-other:before, .the-arrow-other.-left-other > .shaft-other:after {
    width: 0;
    background-color: rgb(58, 57, 57);
  }
  .the-arrow-other.-left-other > .shaft-other:before {
    transform: rotate(0);
  }
  .the-arrow-other.-left-other > .shaft-other:after {
    transform: rotate(0);
  }
  .the-arrow-other.-right-other {
    top: 3px;
  }
  .the-arrow-other.-right-other > .shaft-other {
    width: 1px;
    transition-delay: 0.2s;
  }
  .the-arrow-other.-right-other > .shaft-other:before, .the-arrow-other.-right-other > .shaft-other:after {
    width: 8px;
    transition-delay: 0.3s;
    transition: all 0.5s;
  }
  .the-arrow-other.-right-other > .shaft-other:before {
    transform: rotate(40deg);
  }
  .the-arrow-other.-right-other > .shaft-other:after {
    transform: rotate(-40deg);
  }
  .the-arrow-other > .shaft-other {
    background-color:rgb(58, 57, 57);
    display: block;
    height: 3px;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;
    top: -5px;
  }
  .the-arrow-other > .shaft-other:before, .the-arrow-other > .shaft-other:after {
    background-color:rgb(58, 57, 57);
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    right: 0;
    transition: all 0.2s;
    transition-delay: 0;
  }
  .the-arrow-other > .shaft-other:before {
    transform-origin: top right;
  }
  .the-arrow-other > .shaft-other:after {
    transform-origin: bottom right;
  }
  
  .animated-arrow-other {
    display: inline-block;
    color: rgb(58, 57, 57);
    font-size: 3rem;
    font-style: italic;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
    text-align: center;
  }
  .animated-arrow-other > .main-other {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    text-align: center;
  }
  .animated-arrow-other > .main-other > .text-other {
    margin: 0 24px 0 0;
    line-height: 1;
    font-family: 'Balsamiq Sans', cursive;
    text-align: center;
    color:rgb(58, 57, 57);
  }
  .animated-arrow-other > .main-other > .the-arrow-other {
    position: relative;
  }

@media screen and (max-width: 960px) {
    .animated-arrow-other {
        
        font-size: 2.1em;
        
    }

      
    .swagItem{
        width:150px;
        height: 150px;
       
    }

    .column img {
        width: 150px;
        height: 150px;
        background: white;
        margin: .5em;
        box-shadow: 0 2px 1px rgba(0,0,0,.1);
      }

      .merchTitle{
        font-size: 2.5rem;
        text-align: center;
        margin-top:15px;
        margin-bottom:15px;
        font-family: 'Balsamiq Sans', cursive;
        color:rgb(58, 57, 57);
    }
}

@media screen and (max-width:500px){
  .animated-arrow-other:hover {
    color: rgb(58, 57, 57);
  }
  .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other:before {
    transform: rotate(40deg);
  }
  .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other:after {
    transform: rotate(-40deg);
  }
  .animated-arrow-other:hover > .main-other {
    transform: translateX(2px);
    transform: translateX(10px);
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other {
    width: 0;
    transform: translateX(100%);
    transition-delay: 0;
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:before, .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.4s;
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:before {
    transform: rotate(0);
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:after {
    transform: rotate(0);
  }
}

@media screen and (min-width: 500px) {
  .animated-arrow-other:hover {
    color: rgb(58, 57, 57);
  }
  .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other {
    width: 64px;
    transition-delay: 0.1s;
    background-color:rgb(58, 57, 57);
  }
  .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other:before, .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other:after {
    width: 12px;
    transition-delay: 0.1s;
    background-color: rgb(58, 57, 57);
  }
  .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other:before {
    transform: rotate(40deg);
  }
  .animated-arrow-other:hover > .the-arrow-other.-left-other > .shaft-other:after {
    transform: rotate(-40deg);
  }
  .animated-arrow-other:hover > .main-other {
    transform: translateX(17px);
    transform: translateX(80px);
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other {
    width: 0;
    transform: translateX(100%);
    transition-delay: 0;
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:before, .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.1s;
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:before {
    transform: rotate(0);
  }
  .animated-arrow-other:hover > .main-other > .the-arrow-other.-right-other > .shaft-other:after {
    transform: rotate(0);
  }
  
}