*{
  box-sizing: border-box;
  margin: 0;
  padding: 0%;
  font-family: 'Open Sans', sans-serif;
}

/* body {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background:  url('./images/background2F.png') repeat center center;
  background-size: 550px 350px;
  -webkit-background-size:  550px 350px;
  -moz-background-size:  550px 350px;
  -o-background-size:  550px 350px;
  background-size:  550px 350px;
} */

html{
  width: 100%;
  height: 100%;
  background-image: url('./images/background2F2.png');
  background-color: #a3b9b7;
  background-repeat:repeat;
}

.mainLogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

@media screen and (max-width:500px){
  .mainLogo{
    content: url('./images/logoPerp.png');
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

/* .App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
